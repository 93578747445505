var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editorStyle" },
    [
      _c("Editor", {
        key: _vm.timestamp,
        ref: "editorRef",
        attrs: {
          init: _vm.init,
          disabled: _vm.column.isEdit !== 0 || _vm.isView === "2",
          id: _vm.column.uuid,
        },
        model: {
          value: _vm.myValue,
          callback: function ($$v) {
            _vm.myValue = $$v
          },
          expression: "myValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }