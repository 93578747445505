var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "instruction-container" },
    [
      !_vm.column.lineTypeCss?.titleHide
        ? _c("div", { staticClass: "title-box" }, [
            _vm.column.lineTypeCss.titleStyle === "2"
              ? _c(
                  "span",
                  { staticClass: "iconfont title-icon", style: _vm.iconStyle },
                  [_vm._v("")]
                )
              : _vm._e(),
            _vm.column.lineTypeCss.titleStyle === "3"
              ? _c("span", { staticClass: "line", style: _vm.iconStyle })
              : _vm._e(),
            _vm.column.lineTypeCss.titleStyle === "4"
              ? _c("span", { staticClass: "dian", style: _vm.iconStyle })
              : _vm._e(),
            !_vm.column.required
              ? _c("span", { staticClass: "title", style: _vm.fontStyle }, [
                  _vm._v(_vm._s(_vm.column.subTitle)),
                ])
              : _c(
                  "span",
                  { staticClass: "title titl-2", style: _vm.fontStyle },
                  [_vm._v(_vm._s(_vm.column.subTitle))]
                ),
          ])
        : _vm._e(),
      _c("rich", {
        attrs: { column: _vm.column, businessData: _vm.businessData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }