<!--
 * @Author: pengyu
 * @Date: 2020-07-27 15:01:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 09:54:17
 * @Description: 说明控件
-->
<template>

  <div class="instruction-container">
    <div class="title-box"  v-if="!column.lineTypeCss?.titleHide">
      <span class="iconfont title-icon" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='2'">&#xe853;</span>
      <span class="line" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='3'"></span>
      <span class="dian" :style="iconStyle" v-if="column.lineTypeCss.titleStyle==='4'"></span>
      <span class="title" :style="fontStyle" v-if="!column.required">{{column.subTitle}}</span>
      <span class="title titl-2" :style="fontStyle" v-else>{{column.subTitle}}</span>
    </div>
    <rich :column="column" :businessData="businessData"></rich>
  </div>
</template>

<script>
import MxColumnData from "../MxColumnData.js";
import rich from "./rich-text-components.vue";

export default {
  name: "BMInstructionColumn",
  mixins: [MxColumnData],
  components: {
    rich
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
    };
  },
  computed: {
    // 控制渲染的
    fontStyle() {
      return {
          "font-size": this.column.lineTypeCss.titleFontsize,
          color: this.column.lineTypeCss.titleFontcolor,
          "font-weight": this.column.titleFontbold ? 700 : 400
      };
    },
    iconStyle() {
      return {
        // background: this.column.lineTypeCss.titlePrecolor,
        color: this.column.lineTypeCss.titlePrecolor
      };
    }
  },
  created() {
    console.log(this.column, "ssssssssssss");
  }
};
</script>

<style lang='scss' scoped>
.instruction-container{
  // border: 1px solid $field-border-color;
  border-radius: 5px;
  .title-box{
    display: flex;
    .title-icon{
      color:#FFA32B;
      width: 16px;
    }
    .title{
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #1A1C1E;
    }
  }
  .sep-tit{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid $field-border-color;
    background: #F6FAFD;
    padding-left: 12px;
    font-weight: 600;
  }
  .sep-info{
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
  }
}
.instruction-container{
  // border: 1px solid $field-border-color;
  border-radius: 5px;
  .title-box{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .title-icon{
      color:#FFA32B;
      width: 16px;

    }
    .title{
      margin-left: 10px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #1A1C1E;
    }
    .titl-2::after{
      content: "*";
      color: #ff3e3e;
      margin-left: 4px;
    }
  }
  .sep-tit{
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid $field-border-color;
    background: #F6FAFD;
    padding-left: 12px;
    font-weight: 600;
  }
  .sep-info{
    height: 50px;
    line-height: 50px;
    padding-left: 12px;
  }
}
.line{
      width: 3px;
        height: 16px;
        background: #3e90fe;
        border-radius: 1px;
        display: inline-block;
        vertical-align: middle;
  }
  .dian{
    width: 6px;
    height: 6px;
    background: #3E90FE;
    border-radius: 3px;
}
</style>
